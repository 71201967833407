import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, from, of, Subject, throwError } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PushNativePlugin } from './native';
import { StorageService } from 'src/app/services/storage.service';

const BASE_API = environment.baseUrl;

interface IReportDevice {
  provider: string;
  os: string;
  env: string;
  push_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class PushService {
  private notificationReceiveListener: any = null;
  private pushReceive$ = new Subject();

  private notificationPerformListener: any = null;
  private pushHandle$ = new Subject();
  
  constructor(
    private plugin: PushNativePlugin,
    private httpClient: HttpClient,
    private navCtrl: NavController,
    private storage: StorageService,
  ){}

  // 注册设备
  register() {
    from(this.plugin.bind()).pipe(
      switchMap(bindRes => {
        if (bindRes) {
          const { status, channelId, userId } = bindRes;
          if (status && channelId && userId) {
            // http 绑定
            const deviceInfo: IReportDevice = {
              provider: 'baidu',
              os: this.plugin.platform,
              env: environment.production ? 'prod' : 'dev',
              push_token: channelId
            };
            return this.reportDevice(deviceInfo);
          }
        }
        return of(bindRes)
      })
    ).subscribe((res) => {
      if (res !== "") {
        console.log('bindRes', res);
        this.addListener();
      }
    })
  }

  // 注销设备 如果jwt过期 就不主动注销设备
  logout(overdue = false) {
    return from(this.plugin.unbind()).pipe(
      switchMap(unbindRes => {
        if (unbindRes) {
          const { status } = unbindRes;
          if (status) { this.clear(); }
          if (status && !overdue) {
            this.cancelDevice().pipe(
              catchError(err => {
                return throwError(err)
              })
            );
          }
        }
        return of(null)
      })
    )
  }

  resumeWork() {
    this.plugin.isPluginEnable().then(
      res => {
        if (res && res.isEnabled) {
          this.plugin.resumeWork();
        }
      }
    )
  }

  private addListener() {
    if (!this.notificationReceiveListener) {
      this.notificationReceived();
    }
    if (!this.notificationPerformListener) {
      this.notificationPerformed();
    }
  }

  private clear() {
    try {
      this.notificationPerformListener.remove();
      this.notificationPerformListener = null;

      this.notificationReceiveListener.remove();
      this.notificationReceiveListener = null;
    } catch (error) {
      console.log('clear error', error);
    }
  }

  private notificationReceived() {
    if (!this.plugin.notificationReceived) { return; }
    this.notificationReceiveListener = this.plugin.notificationReceived((res: any) => {
      this.pushReceive$.next(res);
    });
  }

  private notificationPerformed() {
    if (!this.plugin.notificationPerformed) { return; }
    this.notificationPerformListener = this.plugin.notificationPerformed((res: any) => {
      this.pushHandle$.next(res);
      // 如果已登录  跳转到消息中心
      this.storage.getValue<any>('appUser').subscribe(
        res => {
          if (res) {
            this.navCtrl.navigateForward(['./message']);
          }
        }
      )
      
    });
  }

  pushReceived() {
    return this.pushReceive$.pipe(share());
  }

  pushHandled() {
    return this.pushHandle$.pipe(share());
  }

  // 向后台登记设备
  private reportDevice(device: IReportDevice) {
    return this.httpClient.post(`${BASE_API}/user/report-device`, device, {
      headers: {
        'content-type': 'application/json'
      }
    })
  }

  // 向后台注销设备
  private cancelDevice() {
    return this.httpClient.post(`${BASE_API}/user/logout-device`, {}, {
      headers: {
        'content-type': 'application/json'
      }
    })
  }
}