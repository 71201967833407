import { AlertController, ToastController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { AlertOptions } from '@ionic/core';

/**
 * 基础页面类，包含一些所有页面都可能用到的方法
 */
export abstract class BasePage {
  get title(): string {
    return '';
  }

  /**
   * 更新页面和document的title
   */
  protected updateTitle(titleService: Title) {
    if (this.title) {
      titleService.setTitle(this.title);
    } else {
      titleService.setTitle('');
    }
  }

  protected showAlert(
    alertController: AlertController,
    title: string,
    msg?: string,
    handler?: () => void, okText?: string, cancelText?: string
  ) {
    let buttons: any;
    if (cancelText) {
      buttons = [
        {
          text: okText ? okText : '好',
          handler
        },
        {
          text: cancelText,
          role: 'cancel'
        }
      ];
    } else {
      buttons = [
        {
          text: okText ? okText : '好',
          handler,
          role: 'cancel'
        }
      ];
    }
    alertController.create({
      header: title,
      message: msg,
      animated: this.androidVersion >= 7,
      cssClass: ['my-alert'],
      buttons
    }).then(alert => alert.present());
  }

  protected createAlert(alertController: AlertController, option: AlertOptions) {
    if (option instanceof Object) {
      // 安卓版本小于7无法播放动画
      if (this.androidVersion < 7) {
        option.animated = false;
        if (option.cssClass) {
          if (typeof option.cssClass === 'object') {
            option.cssClass.push('my-alert');
          } else {
            option.cssClass = [option.cssClass, 'my-alert'];
          }
        } else {
          option.cssClass = ['my-alert'];
        }
      }
      alertController.create(option).then(alert => alert.present());
    } else {
      alertController.create(option).then(alert => alert.present());
    }
  }

  protected showToast(msg: string) {
    new ToastController().create({
      message: msg,
      duration: 1000,
      position: 'middle',
      cssClass: 'toast-style'
    }).then(toast => toast.present());
  }

  protected showToast1(msg: string) {
    new ToastController().create({
      message: msg,
      duration: 1000,
      position: 'middle',
      cssClass: 'toast-style1'
    }).then(toast => toast.present());
  }

  get isAndroid(): boolean {
    const userAgent = navigator.userAgent;
    const index = userAgent.indexOf('Android');
    return index >= 0;
  }
  get isIos(): boolean {
    const userAgent = navigator.userAgent;
    return !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  }

  get androidVersion(): number {
    const userAgent = navigator.userAgent;
    const index = userAgent.indexOf('Android');
    if (index >= 0) {
      return parseFloat(userAgent.slice(index + 8));
    }
  }
}
