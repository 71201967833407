import { PlateType } from './plate-type.model';

export interface ArticleJson {
  count: number;
  articles: {
    id: number;
    title: string;
    content: string;
    picture_url: string;
    video_url: string;
    url: string;
    date: string;
    types: PlateType[];
  }[];
}

export class Article {

  count: number;
  articles: {
    id: number;
    title: string;
    content: string;
    pictureUrl: string;
    videoUrl: string;
    url: string;
    date: string;
    types: PlateType[];
  }[];


  constructor(json?: ArticleJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: ArticleJson): Article {
    this.count = json.count;
    this.articles = json.articles.map((item) => {
      return {
        id: item.id,
        title: item.title,
        content: item.content,
        pictureUrl: item.picture_url,
        videoUrl: item.video_url,
        url: item.url,
        date: item.date,
        types: item.types
      };
    });
    return this;
  }

}


// 文章详情
export interface ArticleDetailJson {
  id: number;
  title: string;
  content: string;
  picture_url: string;
  video_url: string;
  duration: number;
  url: string;
  date: string;
  types: PlateType[];
  viewed: number;
  forward: number;
  like: number;
  like_status: number;
  favorite_status: number;
  download_url: string;
  watched_duration: number;
}

export class ArticleDetail {
  id: number;
  title: string;
  content: string;
  pictureUrl: string;
  videoUrl: string;
  url: string;
  duration: number;
  date: string;
  types: PlateType[];
  viewed: number;
  forward: number;
  like: number;
  likeStatus: number;
  favoriteStatus: number;
  downloadUrl: string;
  watchedDuration: number;

  constructor(json?: ArticleDetailJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: ArticleDetailJson): ArticleDetail {
    this.id = json.id;
    this.title = json.title;
    this.content = json.content;
    this.pictureUrl = json.picture_url;
    this.videoUrl = json.video_url;
    this.url = json.url;
    this.duration = json.duration;
    this.date = json.date;
    this.types = json.types;
    this.viewed = json.viewed;
    this.forward = json.forward;
    this.like = json.like;
    this.likeStatus = json.like_status;
    this.favoriteStatus = json.favorite_status;
    this.downloadUrl = json.download_url;
    this.watchedDuration = json.watched_duration;
    return this;
  }

  toJsonObject(): ArticleDetailJson {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      picture_url: this.pictureUrl,
      video_url: this.videoUrl,
      url: this.url,
      duration: this.duration,
      date: this.date,
      types: this.types,
      viewed: this.viewed,
      forward: this.forward,
      like: this.like,
      like_status: this.likeStatus,
      favorite_status: this.favoriteStatus,
      download_url: this.downloadUrl,
      watched_duration: this.watchedDuration

    };
  }
}


export interface DurationJson {
  add_duration: number;
  watched_duration: number;
  progress: number;
  first_not_watched_durations: {
    start: number;
    end: number;
  };
  total_progress: number; // 总进度
}

export class Duration {

  addDuration: number;
  watchedDuration: number;
  progress: number;
  firstNotWatchedDurations: {
    start: number;
    end: number;
  };
  total_progress: number;

  constructor(json?: DurationJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: DurationJson): Duration {

    this.addDuration = json.add_duration;
    this.watchedDuration = json.watched_duration;
    this.progress = json.progress;
    this.firstNotWatchedDurations = json.first_not_watched_durations;
    this.total_progress = json.total_progress;
    return this;

  }

}
