import { StorageService } from './services/storage.service';
import { BaseControlService } from 'src/app/services/base-control.service';
import { UserService } from './services/user.service';
import { Component, AfterViewInit, HostListener, NgZone } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { VersionControlService } from './services/version.service';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BasePage } from './_base/base-page';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { switchMap, map, min } from 'rxjs/operators';
import { VersionControl } from './models/version-control.model';
import { throwError, of } from 'rxjs';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

const { Device } = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends BasePage implements AfterViewInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private versionService: VersionControlService,
    private appVersion: AppVersion,
    private alertCtrl: AlertController,
    private userService: UserService,
    private baseControl: BaseControlService,
    private storage: StorageService,
    private mobileAccessibility: MobileAccessibility,
    private zone: NgZone
  ) {
    super();
    this.mobileAccessibility.usePreferredTextZoom(false);
    this.initializeApp();
    // const JSBridge = window.JSBridge || (window.JSBridge = {});
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      this.userService.getLocalUser().subscribe(
        () => {
          this.splashScreen.hide();
        }
      );

      function toNum(versionNum) {
        const a = versionNum.toString();
        const c = a.split('.');
        const numPlace = ['', '0', '00', '000', '0000'];
        const r = numPlace.reverse();
        for (let i = 0; i < c.length; i++) {
          const len = c[i].length;
          c[i] = r[len] + c[i];
        }
        const res = c.join('');
        return res;
      }
      // 版本控制
      Device.getInfo().then((res) => {
        let env: string;
        const platformName = res.platform;
        if (platformName === 'android' || platformName === 'ios') {
          // 测试
          const platformNametest = 'android';
          if (environment.production === true) {
            env = 'prod';
          } else {
            env = 'test';
          }
          this.versionService.getAppLastVersion(platformName, env).subscribe(async (result) => {
            // 当前版本号，比如1.1.0
            const currentVersion = await this.appVersion.getVersionNumber();
            const serviceVersion = result.version;
            const minVersion = result.minVersion;
            const downLoadUrl = result.url;
            const appBugDes = result.remark;
            // 和已知版本做对比
            this.storage.getValue<VersionControl>('lastVersionData').subscribe(data => {
              if (!data) {
                update();
              } else if (data && (data.version !== currentVersion || toNum(currentVersion) < toNum(minVersion))) {
                update();
              }
              // 保存最新版本信息
              this.storage.saveValue<VersionControl>('lastVersionData', result);
            });
            const update = async () => {
              this.zone.run(() => {
                if (toNum(currentVersion) < toNum(minVersion)) {
                  // 强制更新
                  this.showAlert(this.alertCtrl, '版本更新', appBugDes, () => {
                    window.location.href = downLoadUrl;
                  }, '更新');
                  return;
                } else if (toNum(currentVersion) > toNum(minVersion) && toNum(currentVersion) < toNum(serviceVersion)) {
                  // 可以更新
                  this.showAlert(this.alertCtrl, '版本更新', appBugDes, () => {
                    window.location.href = downLoadUrl;
                  }, '更新', '取消');
                  return;
                } else if (toNum(currentVersion) === toNum(serviceVersion)) {
                  return;
                }
              });
            };
          });
        }
      });
    });
  }

  ngAfterViewInit() {
    // document.body.addEventListener('touchstart', touchstartFun, false);
    // function touchstartFun(eve) {
    //   $(eve.target).addClass('clickResponse');
    // }

    // document.body.addEventListener('touchmove', (eve) => {
    //   $(eve.target).removeClass('clickResponse');
    // });

    // document.body.addEventListener('touchend', (eve) => {
    //   $(eve.target).removeClass('clickResponse');
    // });
  }



}
