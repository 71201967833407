import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject, interval, Subscription, timer } from 'rxjs';
import { DrivingLog } from '../models/log.model';

@Injectable()
export class BaseControlService {
    num: Subscription;
    ifCheck: boolean;
    checkCd: number;
    // 是否第一次进入'我的'
    ifFirstTimeInMine = 'ifFirstTimeInMine';
    constructor() { }
    /**
     * 单个素材播放结束
     * 教程id
     */
    StudyVideoFinished: Subject<number> = new Subject();

    /**
     * 单个教程播放结束
     * 教程id
     */
    StudyTrainFinished: Subject<number> = new Subject();

    /**
     * 结束测试
     * 教程id
     */
    TestFinished: Subject<number> = new Subject();

    /**
     * 余额变更时
     */
    ChangeBalance: Subject<number> = new Subject();

    /**
     * 是否显示签名
     * 教程id
     * 是否显示返回键canBack
     */
    SignatureView: Subject<{id: number, canBack: boolean}> = new Subject();

    /**
     * 签名完成
     * 教程id
     */
    SignatureFinish: Subject<number> = new Subject();

    /**
     * 最后一次提交的行车日志
     */
    lastLog: DrivingLog;

    /**
     * 强制提醒人脸识别
     * boolean
     */
    CheckFace: Subject<boolean> = new Subject();

    restCheckFaceCD() {
        if (this.ifCheck) {
            console.log('restCheckFaceCD');
            if (this.num) {
                this.num.unsubscribe();
            }
            this.num = timer(this.checkCd).subscribe(r => {
                if (this.num) {
                    this.num.unsubscribe();
                }
                this.CheckFace.next(true);
            });
        }
    }
    stopCheckFace() {
        console.log('stopCheckFace');
        this.ifCheck = false;
        if (this.num) {
            this.num.unsubscribe();
        }
    }
    startCheckFace(cd: number) {
        console.log('startCheckFace');
        this.ifCheck = true;
        this.checkCd = cd * 60 * 1000;
        this.restCheckFaceCD();
    }

    /**
     * 强制提醒人脸识别
     * boolean
     */
    // tslint:disable-next-line:member-ordering
    StopPlaying: Subject<void> = new Subject();

    // 停止播放视频
    stopPlaying() {
        // console.log('停止播放视频');
        this.StopPlaying.next();
    }
}
