
export interface User {
  id: number;
  nickname: string;
  name:	string;
  phone:	string;
  license_card: string;
  point: number;
  favorite: number;
  sex: string; // 0 女  1 男
  technical_title: Array<string>;
  id_card: string;
  head_portrait: string;
  company_name: string;
  is_bind_company: number; // 是否首次绑定 0否, 1是
  supplier_contact: string;
  supplier_contact_phone: string;
  supplier_name: string;
   // 岗前培训状态  0：未培训  1：进行中，2，培训通过，3：已线下培训
  pre_training_status: number;
  // 运营商旗下app用户定制化欢迎页图片url
  app_welcome_picture_url?: string
}

// 获取用户看书统计
export interface UserBalanceJson {
  available: number;
}

export class UserBalance {
  available: number;
  constructor(json?: UserBalanceJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: UserBalanceJson): UserBalance {
    this.available = json.available;
    return this;
  }

}


// 微信登陆凭证
export interface WeiXinJwtTokenJson {
  jwt: string;
  token: string;
}

export class WeiXinJwtToken {
  jwt: string;
  token: string;
  constructor(json?: WeiXinJwtTokenJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: WeiXinJwtTokenJson): WeiXinJwtToken {
    this.jwt = json.jwt;
    this.token = json.token;
    return this;
  }

}
