import { BaseControlService } from 'src/app/services/base-control.service';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { User, UserBalance, UserBalanceJson } from '../models/user.model';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Product, ProductJson, ClassDetail, ClassDetailJson, RecordJson, Record } from '../models/product.model';
import { NavController, LoadingController } from '@ionic/angular';
import { encrypt } from '../shared/utils/encrypt';
import { PushService } from '../capacitor-plugins/push/push.service';

export class Price {
  index?: number;
  isChecked = false;
  hours: number; // 课时
  discount: number; // 折扣
  amount?: number; // 原始价格
  discount_amount?: number; // 折后价
}
export class PriceData {
  id?: number;
  price_type: number;
  object_type: number;
  object_id: number;
  common_price: number;
  price: number;
  unspent_discount_hours: number;
  trade_key: string;
  price1: Price = new Price();
  price2: Price = new Price();
  price3: Price = new Price();
  price4: Price = new Price();
  price5: Price = new Price();
  remark: string;
}

export class BdStatus {
  license_card: string;
  time: string;
  lat: number;
  lon: number;
  speed: number;
  direction: number;
  status: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  // tslint:disable-next-line: variable-name
  private _user: User;
  readonly storageUser = 'appUser';
  readonly bdVehicle = 'bdVehicle';
  redirectUrl: string;
  constructor(
    private storage: StorageService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private navCtrl: NavController,
    private baseControl: BaseControlService,
    private pushSrv: PushService
  ) { }

  get currentUser(): Observable<User> | User {
    if (this._user) {
      return this._user;
    } else {
      return this.storage.getValue<User>(this.storageUser).pipe(
        map(val => {
          if (val) {
            this._user = val;
            return this._user;
          } else {
            return null;
          }
        })
      );
    }
  }

  setCurrentUser(user: User): Observable<User> {
    this._user = user;
    if (user) {
      return this.storage.saveValue(this.storageUser, user).pipe(
        map(() => user)
      );
    } else {
      return this.storage.removeValue(this.storageUser).pipe(
        map(() => null)
      );
    }
  }

  register(tempId: number, phone: string, code: string): Observable<User> {
    return this.httpClient.post<{ token: string }>(environment.baseUrl + `/noauth/regist`, {
      temp_id: tempId,
      phone: phone + '',
      security_code: code
    }).pipe(
      switchMap(r => this.getWeiXinJwt(r.token)),
      switchMap(() => this.getUserInfo(true)),
    );
  }

  getJwt(phone: string, password: string): Observable<string> {
    let headers = new HttpHeaders();
    const idPass = `${phone}:${password}`;
    headers = headers.set('Authorization', `Basic ${btoa(idPass)}`);
    return this.httpClient.get<{ jwt: string }>(environment.loginUrl + `/noauth/login`, {
      headers
    }).pipe(
      switchMap(result => this.saveLocalJWT(result.jwt)),
      map(jwt => {
        // 如果登录成功，发送通知
        if (this.canDecryptJwt(jwt)) {
          this.notificationService.pushNotification('auth-success', jwt);
          return jwt;
        } else {
          throw new Error('jwt is not valid');
        }
      })
    );
  }

  // 微信登陆接口
  getWeiXinLogin(wcode: string): Observable<User> {
    return this.httpClient.post<{ jwt: string, token: string }>(environment.baseUrl + `/noauth/wechat-login`, {
      code: wcode
    }).pipe(
      switchMap((res) => this.saveLocalJwtToken(res.jwt, res.token)),
      switchMap(() => this.getUserInfo(true)),
    );
  }

  // jwt 过期, 通过token生成新的jwt和token
  getWeiXinJwt(wtoken: string): Observable<{ jwt: string, token: string }> {
    return this.httpClient.get<{ jwt: string, token: string }>(environment.baseUrl + `/noauth/jwt`, {
      params: {
        token: wtoken + '',
      }
    }).pipe(
      switchMap((res) => this.saveLocalJwtToken(res.jwt, res.token)),
      map(r => {
        return r;
      })
    );
  }

  // 【游客】无需登陆，获取jwt
  notNeedLoginGetJwt(): Observable<{ jwt: string, token: string }> {
    return this.httpClient.get<{ jwt: string, token: string }>(environment.baseUrl + `/noauth/temp-user-login`, {
    }).pipe(
      switchMap((res) => this.saveLocalJwtToken(res.jwt, res.token)),
      map(r => {
        return r;
      })
    );
  }

  // 登出 overdue：是否因为jwt被占用而过期
  logOut(overdue = false) {
    // this.clear();
    // 退出之前push设备注销
    this.pushSrv.logout(overdue).subscribe(() => {
      this.storage.clearAll();
      this.clear();
      this._user = null;
      this.storage.removeValue(this.storageUser).subscribe(() => {
        this.navCtrl.navigateRoot(['/login']);
      }, () => {
        this.navCtrl.navigateRoot(['/login']);
      });
    });

    
    
  }


  clear(): Observable<void> {
    return this.storage.removeValue('jwt&token');
  }

  clearAllLocal(): Observable<User> {
    let data: {
      jwt: string;
      token: string;
    };
    let ifFirstTimeInMine: string;
    return this.getLocalJwtToken().pipe(
      map(r => {
        data = r;
        return r;
      }),
      switchMap(() => this.storage.getValue(this.baseControl.ifFirstTimeInMine)),
      map(r => {
        if (r) {
          ifFirstTimeInMine = r;
        }
        return r;
      }),
      switchMap(() => this.storage.clearAll()),
      switchMap(() => this.saveLocalJwtToken(data.jwt, data.token)),
      switchMap(() => this.saveLocalJWT(data.jwt)),
      switchMap(() => this.storage.saveValue(this.baseControl.ifFirstTimeInMine, ifFirstTimeInMine)),
      switchMap(() => this.setCurrentUser(this._user))
    );
  }

  private saveLocalJWT(jwt: string): Observable<string> {
    return this.storage.saveValue('jwt', jwt);
  }

  private saveLocalJwtToken(jwt: string, token: string): Observable<{ jwt: string, token: string }> {
    return this.storage.saveValue('jwt&token', { jwt, token });
  }


  getLocalJwtToken(): Observable<{ jwt: string, token: string }> {
    return this.storage.getValue('jwt&token');
  }


  /*
   * jwt是否能正确解码
   * @param jwt
   */
  canDecryptJwt(jwt: string): boolean {
    try {
      const payload = jwt.split('.')[1];
      JSON.parse(decodeURIComponent(escape(atob(payload))));
      return true;
    } catch (error) {
      console.error(`jwt解码失败:${error}`);
      return false;
    }
  }

  loginWithLocalCredential(): Observable<{ jwt: string, token: string }> {
    return this.storage.getValue<{ jwt: string, token: string }>('jwt&token').pipe(
      switchMap(u => {
        if (u) {
          return this.getWeiXinJwt(u.token);
        } else {
          throw new Error('no local');
        }
      }),
      map(res => res)
    );
  }

  getUserInfo(fromLogin = false): Observable<User> {
    return this.httpClient.get<User>(environment.baseUrl + `/user-info`).pipe(
      map(r => {
        if (fromLogin) {
          // 注册push设备
          this.pushSrv.register();
        }
        this.storage.saveValue<User>(this.storageUser, r);
        this._user = r;
        return this._user;
      })
    );
  }
  getIfChangeUser(): Observable<boolean> {
    return this.storage.getValue<{ ids: number[] }>('ifChangeUser').pipe(
      map(r => {
        if (r && r.length === 2 && r[0] !== r[1]) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getLocalUser(): Observable<User> {
    if (this._user) {
      return of(this._user);
    } else {
      return this.storage.getValue<User>(this.storageUser).pipe(
        switchMap(r => {
          if (r) {
            this._user = r;
          }
          return of(r);
        })
      );
    }
  }

  editUserName(username: string): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/user-info`, {
      nickname: username,
    }).pipe(
      map(() => null)
    );
  }

  editPhone(phone: string, code: string): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/user-info`, {
      phone,
      security_code: code
    }).pipe(
      map(() => null)
    );
  }

  editLicenceCard(licenseCard: string): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/user-info`, {
      license_card: licenseCard,
    }).pipe(
      map(() => null)
    );
  }

  editUserAvatar(imgurl: string): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/user-info`, {
      head_portrait: imgurl,
    }).pipe(
      map(() => null)
    );
  }


  editPassword(phone: string, password: string): Observable<void> {
    return this.httpClient.post(environment.baseUrl + ``, {
      phone: phone + '',
      password: password + '',
    }).pipe(
      map(() => null)
    );
  }

  checkUserInfo(name?: string, idCard?: string, sex?: string, technicalTitle?: string, licenseCard?: string): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/user-info`, {
      name,
      id_card: idCard + '',
      sex,
      technical_title: technicalTitle,
      license_card: licenseCard
    }).pipe(
      map(() => null)
    );
  }

  // 确认我的信息
  confrimUserInfo(): Observable<void> {
    return this.httpClient.post<void>(environment.baseUrl + `/user-info/confirm`, {
    }).pipe(
      map(json => json)
    );
  }
  // 获取用户余额
  getUserBalance(): Observable<UserBalance> {
    return this.httpClient.get<UserBalanceJson>(environment.baseUrl + `/payment/users/balance`, {
    }).pipe(
      map((json) => {
        return new UserBalance(json);
      })
    );
  }

  getVerifyCode(phone: string): Observable<void> {
    return this.httpClient.post(environment.baseUrl + `/noauth/sms/security-code`, {
      telephone: phone
    }).pipe(
      map(() => null)
    );
  }

  // 校验验证码是否正确
  checkVerifyCode(code: string, phone: string): Observable<boolean> {
    return this.httpClient.post<{ valid: boolean }>(environment.baseUrl + `/noauth/security-code/check`, {
      security_code: code,
      phone
    }).pipe(
      map(json => json.valid)
    );
  }

  // 支付--产品列表
  getProductlist(): Observable<Product[]> {
    return this.httpClient.get<ProductJson[]>(environment.baseUrl + `/payment/products`, {
    }).pipe(
      map(json => json.map(item => new Product(item)))
    );
  }
  getPriceInfo(): Observable<PriceData> {
    let params = new HttpParams();
    params = params.set('object_type', '3');
    return this.httpClient.get<PriceData>(environment.baseUrl + `/payment/prices/info`, {
      // tslint:disable-next-line:object-literal-shorthand
      params: params
    });
  }
  // 课时明细
  getClassDetaillist(): Observable<ClassDetail[]> {
    return this.httpClient.get<ClassDetailJson[]>(environment.baseUrl + `/payment/account-details`, {
    }).pipe(
      map(json => json.map(item => new ClassDetail(item)))
    );
  }

  // 充值记录
  getRecordslist(offset: number, limit: number): Observable<Record[]> {
    return this.httpClient.get<RecordJson[]>(environment.baseUrl + `/payment/topup-records`, {
      params: {
        offset: offset + '',
        limit: limit + '',
      }
    }).pipe(
      map(json => json.map(item => new Record(item)))
    );
  }


  // 检验手机号是否存在
  checkTelPhoneUser(username: string): Observable<boolean> {
    return this.httpClient.get<{ result: boolean }>(environment.baseUrl + `/noauth/phone-login/${username}/is-bind`)
      .pipe(
        map(json => json.result)
      );
  }

  // 手机号登陆
  getTelphoneLoginJwt(phone: string, password: string): Observable<{ jwt: string, token: string }> {
    let headers = new HttpHeaders();
    const saveObj = {
      username: phone,
      password,
      login_timestamp: new Date().getTime()
    };
    const idPass = JSON.stringify(saveObj);
    const token = encrypt(saveObj);
    headers = headers.set('Authorization', `Basic ${btoa(idPass)}`);
    return this.httpClient.get<{ jwt: string, token: string }>(environment.baseUrl + `/noauth/phone-login/v2?token=${token}`, {
      headers,
    }).pipe(
      switchMap((res) => this.saveLocalJwtToken(res.jwt, res.token)),
      map(r => {
        return r;
      })
    );
  }

  // 设置密码
  putUserPassword(phone: string, psd: string, code: string): Observable<void> {
    return this.httpClient.put<void>(environment.baseUrl + `/noauth/password`, {
      phone,
      password: psd,
      security_code: code
    }).pipe(
      map(() => null)
    );
  }

  // 手机号用户注册 或者 手机号存在无密码
  telphoneUserRegister(phone: string, password: string, code: string): Observable<User> {
    return this.httpClient.post<{ token: string }>(environment.baseUrl + `/noauth/regist`, {
      phone,
      password,
      security_code: code
    }).pipe(
      switchMap(r => this.getWeiXinJwt(r.token)),
      switchMap(() => this.getUserInfo()),
    );
  }

  // 保存最后一次北斗查询的车牌号
  saveBd(param: {
    userId: number;
    license: string;
    color: number;
  }) {
    return this.storage.saveValue<{
      userId: number;
      license: string;
      color: number;
    }>(this.bdVehicle, param);
  }

  // 获取最后一次的北斗车牌
  getBd(id): Observable<{
    license: string;
    color: number;
  }> {
    return this.storage.getValue<{
      userId: number;
      license: string;
      color: number;
    }>(this.bdVehicle).pipe(
      map(r => {
        if (r && r.userId === id) {
          return {
            license: r.license,
            color: r.color
          };
        } else {
          return {
            license: '',
            color: null
          };
        }
      })
    );
  }

  checkBd(license_card: string, license_color: number): Observable<BdStatus> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('license_card', license_card);
    queryParams = queryParams.set('license_color', license_color + '');
    return this.httpClient.get<BdStatus>(environment.baseUrl + '/vehicle/status', {
      params: queryParams
    });
  }

  getDailyTask() {

  }
}
