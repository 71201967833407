import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, race } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { createServiceErrorObservable } from './error.model';
import { NotificationService } from '../notification.service';
import { UserService } from '../user.service';
import { AlertController } from '@ionic/angular';
import { BasePage } from 'src/app/_base/base-page';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService extends BasePage implements HttpInterceptor {
  private remoteLoginAlert = false;
  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private alertCtrl: AlertController
  ) {
    super();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return race(next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.brodcastLoginRequired(req);
              break;
            case 403: {
              if (req.url.match(/^.+\/noauth\/login*$/)) {
                this.brodcastLoginRequired(req);
                break;
              } else if (req.url.match(/^.+\/noauth\/jwt*$/)) {
                if (!this.remoteLoginAlert) {
                  this.remoteLoginAlert = true;
                  this.showError('提示', '你的账户已在另一台设备上登陆', () => {
                    this.remoteLoginAlert = false;
                    this.userService.logOut();
                  });
                }
                break;
              } else {
                // 如若手机号登陆，需要改成loginWithLocalCredential1
                return this.userService.loginWithLocalCredential().pipe(
                  catchError(() => {
                    this.brodcastLoginRequired(req);
                    return createServiceErrorObservable(error);
                  }),
                  switchMap(res => {
                    // 使用新jwt
                    const reqWithoutJwt = req.clone({
                      setHeaders: {
                        // tslint:disable-next-line:object-literal-key-quotes
                        'Authorization': `Bearer ${res.jwt}`
                      }
                    });
                    return next.handle(reqWithoutJwt);
                  })
                );
              }
            }
            default:
              break;
          }
        }
        return createServiceErrorObservable(error);
      })
    )
    );
  }

  private brodcastLoginRequired(originalReq: HttpRequest<any>) {
    this.notificationService.pushNotification('auth-failed', originalReq);
  }

  private showError(title: string, msg?: string, hander?: () => void) {
    // this.alertCtrl.create().then(alert => alert.present());
    this.createAlert(this.alertCtrl, {
      header: title,
      message: msg,
      buttons: [
        {
          text: '好',
          handler: hander
        }
      ]
    });
  }
}
