import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {
  Train, TrainJson,
  TrainDetail, TrainDetailJson,
  UserTrainJson, UserTrain,
  UserProgress, UserProgressJson, TrainingsArticleDetail, TrainingsArticleDetailJson, ArticleQuestion, ArticleQuestionJson
} from '../models/task.model';
import { DurationJson, Duration } from '../models/article.model';
import { Order, OrderJson, QueryOrder, QueryOrderJson } from '../models/product.model';


@Injectable({
  providedIn: 'root'
})

export class TaskService {

  constructor(private httpClient: HttpClient) { }

  getTaskList(): Observable<Train[]> {
    return this.httpClient.get<TrainJson[]>(environment.baseUrl + `/trainings`, {
    }).pipe(
      map(json => json.map(item => new Train(item)))
    );
  }

  getTasks(): Observable<Train[]> {
    return this.httpClient.get<TrainJson[]>(environment.baseUrl + `/trainings/ongoing`, {
    }).pipe(
      map(json => json.map(item => new Train(item)))
    );
  }

  getTaskDetail(id: number): Observable<TrainDetail> {
    return this.httpClient.get<TrainDetailJson>(`${environment.baseUrl}/trainings/${id}`, {

    }).pipe(
      map(item => {
        // alert('http' + JSON.stringify(item));
        return new TrainDetail(item);
      })
    );
  }

  // 获取用户的所有学习计划列表
  getAllTaskLists(date?: string): Observable<UserTrain[]> {
    return this.httpClient.get<UserTrainJson[]>(environment.baseUrl + `/trainings/progress`, {
      params: {
        date
      }
    }).pipe(
      map(json => json.map(item => new UserTrain(item)))
    );
  }

  // 获取用户看书统计
  getUserProgress(): Observable<UserProgress> {
    return this.httpClient.get<UserProgressJson>(environment.baseUrl + `/user/progress`, {
    }).pipe(
      map(json => new UserProgress(json))
    );
  }

  // 获取学习计划里文章的完成情况
  getTrainingsArticleDetail(trainingId: number, articleId: number): Observable<TrainingsArticleDetail> {
    return this.httpClient.get<TrainingsArticleDetailJson>(environment.baseUrl + `/trainings/${trainingId}/articles/${articleId}`, {
    }).pipe(
      map(json => new TrainingsArticleDetail(json))
    );
  }

  // 更新学习计划文章的观看时长
  updateTrainingsArticle(trainingId: number, articleId: number, startTime: number, endTime: number): Observable<Duration> {
    return this.httpClient.put<DurationJson>(
      environment.baseUrl + `/trainings/${trainingId}/articles/${articleId}/watched-duration`, {
      start: startTime,
      end: endTime,
    }).pipe(
      map(json => new Duration(json))
    );
  }

  // 买学习计划
  buyOfTraining(trainingId: number): Observable<void> {
    return this.httpClient.post(environment.baseUrl + `/payment/trainings/${trainingId}/buy`, {
    }).pipe(
      map(() => null)
    );
  }

  // 生成订单
  createProductOrder(option: {
    hours: number;
    price_id: number;
    discount: number;
    remark: string;
    trade_key: string;
  }): Observable<Order> {
    return this.httpClient.post<OrderJson>(environment.baseUrl + `/payment/wechat/order`, option).pipe(
      map(item => new Order(item))
    );
  }

  createHoursOrder(option: {
    hours: number;
    price_id: number;
    remark: string;
    amount: number;
    trade_key: string;
  }): Observable<Order> {
    return this.httpClient.post<OrderJson>(environment.baseUrl + `/payment/wechat/order`, option).pipe(
      map(item => new Order(item))
    );
  }

  // 查询订单状态
  queryOrderStatus(id: number): Observable<QueryOrder> {
    return this.httpClient.get<QueryOrderJson>(environment.baseUrl + `/payment/wechat/order/${id}/query`, {
    }).pipe(
      map(json => new QueryOrder(json))
    );
  }

  // 获取支付的常量信息
  getPayConst(): Observable<number> {
    return this.httpClient.get<{ hours_unitprice: number }>(environment.baseUrl + `/payment/constant`, {
    }).pipe(
      map(json => json.hours_unitprice)
    );
  }

  // 获取教学计划的测试题列表
  getQuestionsList(trainingId: number): Observable<ArticleQuestion[]> {
    return this.httpClient.get<ArticleQuestionJson[]>(environment.baseUrl + `/trainings/${trainingId}/questions`, {
    }).pipe(
      map(json => json.map(item => new ArticleQuestion(item)))
    );
  }

  // 一轮结束更新测试题答案
  // tslint:disable-next-line:max-line-length
  updateQuestionsAnswer(trainingId: number, rightIds: number[], wrongList: { question_id: number; question_option_id: number }[]): Observable<void> {
    return this.httpClient.put(environment.baseUrl + `/trainings/${trainingId}/question-answers`, {
      is_right_question_ids: rightIds,
      is_wrong_question_ids: wrongList
    }).pipe(
      map(() => null)
    );
  }
  // 回顾
  reviewQuestionsList(trainingId: number): Observable<ArticleQuestion[]> {
    return this.httpClient.get<ArticleQuestionJson[]>(environment.baseUrl + `/trainings/${trainingId}/questions-first-answers`, {
    }).pipe(
      map(json => json.map(item => new ArticleQuestion(item)))
    );
  }


}
