import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CameraResultType, CameraSource, Plugins } from '@capacitor/core';
import { ModalController, ToastController } from '@ionic/angular';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

const { Device } = Plugins;

@Component({
  selector: 'app-user-info-validate',
  templateUrl: './user-info-validate.component.html',
  styleUrls: ['./user-info-validate.component.scss'],
})
export class UserInfoValidateComponent implements OnInit {
  @Input()
    set user(user: User) {
      this._user = user;
    }

  _user: User & { technical_title_str?: string } = null;
  currentStep = -1;

  userForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      idCard: ['', Validators.required],
      gender: ['', Validators.required],
      technicals: [[], Validators.required],
      licenceCard: ['', Validators.required]
    });
    this.initData();
  }

  initData() {
    if (!this._user) { return; }
    const technicals = this._user.technical_title || [];
    this._user.technical_title_str = technicals.join(',');
    switch (this._user.is_bind_company) {
      case 0:
        this.currentStep = 1;
        break;
      case 1:
        this.currentStep = 2;
        break;
      default:
        this.currentStep = -1;
        break;
    }

    this.userForm.patchValue({
      name: this._user.name,
      idCard: this._user.id_card,
      gender: this._user.sex,
      technicals: this._user.technical_title,
      licenceCard: this._user.license_card
    });
  }

  // 第一步信息确认
  onConfirmUserInfo() {
    this.userSrv.confrimUserInfo().subscribe(
      () => {
        this.currentStep = 2;
        this.userSrv.getUserInfo().subscribe(
          r => {
            this._user = r;
          }
        );
      }
    );
  }

  // 第二步拍照上传
  async onTakePhoto() {
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
      const image = await Plugins.Camera.getPhoto({
        quality: 80,
        source: CameraSource.Camera,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        width: 512
      });
      // 同步到服务器端
      this.userSrv.editUserAvatar(image.base64String.replace(/[\r\n]/g, '')).subscribe(
        () => {
          this.toastCtrl.create({
            message: '上传成功',
            position: 'middle',
            duration: 1000,
            cssClass: 'toast-style'
          }).then(toast => { toast.present(); });
          // 关闭弹窗
          this.modalCtrl.dismiss({ res: 'success' });
      });
    }
  }
}
