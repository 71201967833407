import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import * as localforage from 'localforage';
import { environment } from 'src/environments/environment';

/**
 * 获取和存储对象到本地
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // tslint:disable-next-line:variable-name
  private readonly _storage: LocalForage;

  constructor() {
    this._storage = localforage.createInstance({
      name: environment.database.name,
      storeName: environment.database.tables.core
    });
  }

  /**
   * 保存一个值到本地
   */
  saveValue<T>(key: string, value: T): Observable<T> {
    return from(this._storage.setItem(key, value))
      .pipe(
        map(() => value)
      );
  }

  /**
   * 从本地获取一个值
   */
  getValue<T>(key: string): Observable<any> {
    return from(this._storage.getItem(key));
  }

  /**
   * 从本地删除一个值
   */
  removeValue<T>(key: string): Observable<void> {
    return from(this._storage.removeItem(key));
  }

  clearAll() {
    return from(this._storage.clear());
  }
}
