import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'loading-page', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'forget', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: 'reset', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  {
    path: 'home/me/phone', loadChildren: () =>
      import('./mine/edit-phone-number/edit-phone-number.module').then(m => m.EditPhoneNumberModule)
  },
  { path: 'home/me/contact', loadChildren: () => import('./mine/edit-contact/edit-contact.module').then(m => m.EditContactModule) },
  { path: 'home/me/license', loadChildren: () => import('./mine/update-licence/update-licence.module').then(m => m.UpdateLicenceModule) },
  { path: 'collection', loadChildren: () => import('./my-collection/my-collection.module').then(m => m.MyCollectionModule) },
  { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
  { path: 'message', loadChildren: () => import('./message/message.module').then(m => m.MessageModule) },
  { path: 'message-detail/:id', loadChildren: () => import('./message-detail/message-detail.module').then(m => m.MessageDetailModule) },
  { path: 'list/:type', loadChildren: () => import('./list/list.module').then(m => m.ListModule) },
  { path: 'detail/:id', loadChildren: () => import('./details/details.module').then(m => m.DetailsModule) },
  { path: 'ebooks', loadChildren: () => import('./e-books/e-books.module').then(m => m.EBooksModule) },
  { path: 'ebooks/detail/:id', loadChildren: () => import('./e-books-detail/e-books-detail.module').then(m => m.EBooksDetailModule) },
  { path: 'ktest', loadChildren: () => import('./knowladge-test/knowladge-test.module').then(m => m.KnowladgeTestModule) },
  { path: 'percenter', loadChildren: () => import('./person-center/person-center.module').then(m => m.PersonCenterModule) },
  { path: 'task/detail/:id', loadChildren: () => import('./task-detail/task-detail.module').then(m => m.TaskDetailModule) },
  { path: 'task', loadChildren: () => import('./new-task/new-task.module').then(m => m.NewTaskPageModule) },
  { path: 'study/:id', loadChildren: () => import('./task-study/task-study.module').then(m => m.TaskStudyModule) },
  {
    path: 'me/learning', loadChildren: () =>
      import('./learning-situation/learning-situation.module').then(m => m.LearningSituationModule)
  },
  { path: 'signIn', loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'plugin-test', loadChildren: () => import('./plugin-test/plugin-test.module').then(m => m.PluginTestPageModule) },
  { path: 'account', loadChildren: () => import('./personal-account/personal-account.module').then(m => m.PersonalAccountModule) },
  { path: 'telphone-login', loadChildren: () => import('./telphone-login/telphone-login.module').then(m => m.TelphoneLoginModule) },
  { path: 'psd-login', loadChildren: () => import('./psd-login/psd-login.module').then(m => m.PsdLoginModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'signature', loadChildren: () => import('./signature/signature.module').then(m => m.SignatureModule) },
  { path: 'test-question-old/:id', loadChildren: () => import('./test-question/test-question.module').then(m => m.TestQuestionModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'test-question/:id', loadChildren: () => import('./test-question-new/test-question.module').then(m => m.TestQuestionNewModule) },
  { path: 'review/:id', loadChildren: () => import('./review-question/review-question.module').then(m => m.ReviewQuestionModule) },
  { path: 'wait/:id', loadChildren: () => import('./wait-page/wait-page.module').then(m => m.WaitPageModule) },
  { path: 'policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'logdetail', loadChildren: () => import('./driving-log-detail/driving-log-detail.module').then(m => m.DrivingLogDetailModule) },
  { path: 'log', loadChildren: () => import('./driving-log/driving-log.module').then(m => m.DrivingLogModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'loghistory', loadChildren: () => import('./driving-log-history/driving-log-history.module').then(m => m.DrivingLogHistoryModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'security', loadChildren: () => import('./vehicle-security-check/vehicle-security-check.module').then(m => m.VehicleSecurityCheckModule) },
  { path: 'way-bill', loadChildren: () => import('./way-bill/way-bill.module').then(m => m.WayBillModule) },
  { path: 'new-way-bill', loadChildren: () => import('./way-bill-form/way-bill-form.module').then(m => m.WayBillFormModule) },
  { path: 'way-bill-detail/:id', loadChildren: () => import('./way-bill-detail/way-bill-detail.module').then(m => m.WayBillDetailModule) },
  { path: 'loading-page', loadChildren: './loading-page/loading-page.module#LoadingPagePageModule' },
  { path: 'promising', loadChildren: './promising/promising.module#PromisingPageModule' },
  { path: 'question-bank', loadChildren: './question-bank/question-bank.module#QuestionBankModule' },
  { path: 'feed-back', loadChildren:  () => import('./feed-back/feed-back.module').then(m => m.FeedBackModule) },
  { path: 'feed-back-notify', loadChildren:  () => import('./feed-back-notify/feed-back-notify.module').then(m => m.FeedBackNotifyModule) },
  { path: 'test-health', loadChildren:  () => import('./test-health/test-health.module').then(m => m.TestHealthModule) },
  {
    path: 'pre-training-exam',
    loadChildren: () => import('./new-task/pre-job-training-exam/pre-job-training-exam.module').then(
      m => m.PreJobTrainingExamPageModule
    )
  },
  {
    path: 'pre-training-file-sign',
    loadChildren: () => import('./new-task/pre-training-file-sign/pre-training-file-sign.module').then(
      m => m.PreTrainingFileSignPageModule
    )
  },
  { path: '**', redirectTo: 'loading-page', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
