import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushNativePlugin {
  _platform: any;
  constructor() {
    Device.getInfo().then(
      info => {
        this._platform = info.platform || '';
      }
    )
  }

  private get plugin() {
    return (this._platform === 'android' || this._platform === 'ios') ? Plugins.SJPush : null;
  }

  get platform() {
    return this._platform;
  }

  bind(): Promise<any> {
    if (!this.plugin) { return Promise.resolve(""); }
    return this.plugin.bindForApp();
  }

  unbind(): Promise<any> {
    if (!this.plugin) { return Promise.resolve(""); }
    // 安卓系统unbind 会闪退
    return this._platform === 'android' ? Promise.resolve("") : this.plugin.unbindForApp();
  }

  isPluginEnable(): Promise<any> {
    if (!this.plugin) { return Promise.resolve(""); }
    return this._platform === 'ios' ? Promise.resolve("") : this.plugin.isPushEnabled();
  }

  resumeWork() {
    if (!this.plugin) { return Promise.resolve(""); }
    return this._platform === 'ios' ? Promise.resolve("") : this.plugin.resumeWork();
  }


  notificationReceived(callback: (res: any) => void) {
    if (!this.plugin) { return; }
    return this.plugin.addListener('pushNotificationReceived', (notification: any) => {
      const notifyData = this.handleNotification(notification);
      callback(notifyData);
    });
  }

  notificationPerformed(callback: (res: any) => void) {
    if (!this.plugin) { return; }
    return this.plugin.addListener('pushNotificationActionPerformed', (actionPerform: any) => {
      const notifyData = this.handleNotificationPerform(actionPerform);
      callback(notifyData);
    });
  }

  private handleNotification(notification: any) {
    if (this._platform === 'android') {
      const { title, description, customStr } = notification;
      const customData = customStr ? JSON.parse(customStr) : {};
      return {
        title,
        subtitle: '',
        body: description,
        customData,
        aps: null,
        platform: 'android',
      };
    } else if (this._platform === 'ios') {
      const { title, subtitle, body, data } = notification;
      const { aps, ...customData } = data;
      return {
        title,
        subtitle,
        body,
        customData,
        aps,
        platform: 'ios'
      };
    }
    
  }

  private handleNotificationPerform(perform: any) {
    if (this._platform === 'ios') {
      const { notification } = perform;
      return this.handleNotification(notification);
    } else if (this._platform === 'android') {
      return this.handleNotification(perform);
    }
  }
}