import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})

export class SignatureService {

  constructor( private httpClient: HttpClient) { }

   // 签名
   getSignature( id: number, signatureUrl: string ): Observable<void> {
    return this.httpClient.post(environment.baseUrl + `/trainings/${id}/signature`, {
      signature: signatureUrl,
    }).pipe(
      map(() => null)
    );
  }


}
