import { SignatureComponent } from './new-task/signature/signature.component';
import { UserService } from './services/user.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { authHttpInterceptorProviders } from './services/auth';
import { errorHttpInterceptorProviders } from './services/error';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SignaturePadModule } from 'angular2-signaturepad';
import { BaseControlService } from './services/base-control.service';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { markedOptionsFactory } from './_base/marked-options';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { UserInfoValidateModule } from './user-info-validate/user-info-validate.module';

@NgModule({
  declarations: [
    AppComponent,
    SignatureComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SignaturePadModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory
      }
    }),
    UserInfoValidateModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AppMinimize,
    AppVersion,
    MobileAccessibility,
    UserService,
    BaseControlService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    authHttpInterceptorProviders,
    errorHttpInterceptorProviders,
    Events
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
