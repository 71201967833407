import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../user.service';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dealedReq = req;
    // noauth方法无需jwt，其他方法都需要加上jwt
    if (req.url.match(/^.+\/noauth\/.+$/)) {
      return next.handle(req);
    } else if (!req.headers.get('Authorization')) {
      return this.userService.getLocalJwtToken().pipe(
        map(res => {
          const authReq = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${res.jwt}`
            }
          });
          dealedReq = authReq;
        }),
        switchMap(() => next.handle(dealedReq))
      );
    }
    return next.handle(dealedReq);
  }
}
