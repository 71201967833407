
// 支付产品列表
export interface ProductJson {
    id: string;
    name: string;
    description: string;
    normal_lesson_hours: number;
    attached_free_lesson_hours: number; // 附送课时
    total_lesson_hours: number; // 总课时；正常课时+附送的课时
    normal_price: number;
    discount_price: number;
    total_price: number;
    isChecked: boolean;
}

export class Product {

  id: string;
  name?: string;
  description: string;
  normalLessonHours: number;
  attachedFreeLessonHours: number;
  totalLessonHours: number;
  normalPrice: number;
  discountPrice: number;
  totalPrice: number;
  isChecked = false;

  constructor(json?: ProductJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: ProductJson): Product {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.normalLessonHours = json.normal_lesson_hours;
    this.attachedFreeLessonHours = json.attached_free_lesson_hours;
    this.totalLessonHours = json.total_lesson_hours;
    this.normalPrice = json.normal_price;
    this.discountPrice = json.discount_price;
    this.totalPrice = json.total_price;
    this.isChecked = json.isChecked;

    return this;
  }

}


// 课时明细
export interface ClassDetailJson {
  month_date: string;
  list: {
    id: number;
    change_reason: string;
    change_hours: number;
    change_time: string;
  }[ ];

}

export class ClassDetail {
  monthDate: string;
  list: {
    id: number;
    changeReason: string;
    changeHours: number;
    changeTime: string;
  }[ ];

  constructor(json?: ClassDetailJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: ClassDetailJson): ClassDetail {
    this.monthDate = json.month_date;
    this.list = json.list.map( (item) => {
      return {
        id: item.id,
        changeReason: item.change_reason,
        changeHours: item.change_hours,
        changeTime: item.change_time
      };
  });
    return this;
  }

}


// 充值记录
export interface RecordJson {
  id: number;
  product_id: number;
  lesson_hours: number;
  buyer_pay_amount: number; // 实际付钱
  gmt_payment: number;
}

export class Record {
  id: number;
  productId: number;
  lessonHours: number;
  buyerPayAmount: number; // 实际付钱
  gmtPayment: number;

constructor(json?: RecordJson) {
  if (json) {
    this.parse(json);
  }
}

parse(json: RecordJson): Record {
  this.id = json.id;
  this.productId = json.product_id;
  this.lessonHours = json.lesson_hours;
  this.buyerPayAmount = json.buyer_pay_amount;
  this.gmtPayment = json.gmt_payment;

  return this;
}

}


// 生成订单
export interface OrderJson {
  trade_id: number;
  appid: string;
  partnerid: string; // 商户号
  prepayid: string; // 预支付交易会话标识
  package: string; // 扩展字段
  noncestr: string; // 随机字符串
  timestamp: string;
  sign: string;
}

export class Order {
  tradeId: number;
  appid: string;
  partnerid: string; // 商户号
  prepayid: string; // 预支付交易会话标识
  package: string; // 扩展字段
  noncestr: string; // 随机字符串
  timestamp: string;
  sign: string;

constructor(json?: OrderJson) {
  if (json) {
    this.parse(json);
  }
}

parse(json: OrderJson): Order {
  this.tradeId = json.trade_id;
  this.appid = json.appid;
  this.partnerid = json.partnerid;
  this.prepayid = json.prepayid;
  this.package = json.package;
  this.noncestr = json.noncestr;
  this.timestamp = json.timestamp;
  this.sign = json.sign;

  return this;
}

}


// 查询订单订单
export interface QueryOrderJson {
  trade_id: number; // 微信订单id
  trade_state_desc: string;
  trade_status: string;
}

export class QueryOrder {
  tradeId: number; // 微信订单id
  tradeStateDesc: string;
  tradeStatus: string;

constructor(json?: QueryOrderJson) {
  if (json) {
    this.parse(json);
  }
}

parse(json: QueryOrderJson): QueryOrder {
  this.tradeId = json.trade_id;
  this.tradeStateDesc = json.trade_state_desc;
  this.tradeStatus = json.trade_status;

  return this;
}

}













