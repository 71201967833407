// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  baseUrl: 'https://kdstest.sjfkpt.com/backend/apigateway/app',
  // baseUrl: 'https://kdstest.sjfkpt.com/backend/app',
  loginUrl: 'https://kdstest.sjfkpt.com/backend/apigateway/ca/app',
  jwtKey: 'app_test_jwt',
  cd: 1,
  database: {
    name: 'app-test',
    tables: {
      core: 'core',
      cars: 'cars'
    }
  },
  wxAppId: 'wx8ebc7815e8085207',
  qrcode_url: 'https://kdscompanytest.sjfkpt.com/',
  feedback_url: 'https://kdstest.sjfkpt.com/backend/apigateway/interactive',
  exam_url: 'https://kdstest.sjfkpt.com/backend/apigateway/examination',
  apigateway_url: 'https://kdstest.sjfkpt.com/backend/apigateway/',
  prejob_training_api: 'pre-training'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.import { Environment } from './environment.interface';
import { Environment } from './environment.interface';

