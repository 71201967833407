
// app 版本控制
export interface VersionControlJson {
 app_id: number;
 os: string; // 系统
 env: string; // 测试环境;
 remark: string;
 url: string;
 version: string;
 min_version: string; // 最小兼容版本
}

export class VersionControl {

  appId: number;
  os: string; // 系统
  env: string; // 测试环境;
  remark: string;
  url: string;
  version: string;
  minVersion: string; // 最小兼容版本

  constructor(json?: VersionControlJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: VersionControlJson): VersionControl {
    this.appId = json.app_id;
    this.os = json.os;
    this.env = json.env;
    this.remark = json.remark;
    this.url = json.url;
    this.version = json.version;
    this.minVersion = json.min_version;

    return this;
  }

}














