import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { NotificationService } from '../notification.service';
import { StorageService } from '../storage.service';
import { UserService } from '../user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: StorageService,
    notificationService: NotificationService,
    private userService: UserService) {
    notificationService.getSubscriber('auth-failed').subscribe(() => this.userService.clear());
  }

  getRedirectRoute(): Observable<string> {
    return this.storageService.getValue<string>('redirect_route');
  }

  /**
   * 保存路径到本地避免因跳出域名导致的路径丢失问题
   * @param route 路径，非完整url
   */
  setRedirectRoute(route: string): Observable<string> {
    return this.storageService.saveValue<string>('redirect_route', route);
  }



}
