import { BaseControlService } from 'src/app/services/base-control.service';
import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { SignatureService } from '../../services/signature.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController, NavController, AlertController } from '@ionic/angular';
import { TaskService } from '../../services/task.service';
import { BasePage } from '../../_base/base-page';
import { SerivceError } from '../../services/error/error.model';


@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})


export class SignatureComponent extends BasePage implements OnInit, AfterViewInit {

  signature = '';
  isDrawing = false;
  noCanClickSubmit = true;
  @Input() traningId: number;
  @Input() isFromLearning: string;
  @Input() asComponent = false;
  taskArticleList = [];
  beginPlayPositionArray = [];

  @ViewChild('SignaturePad', { static: false }) signaturePad: SignaturePad;
  ifShowSignature: boolean;
  ifCanBack: boolean;

  constructor(
    private signatureService: SignatureService,
    private activedRoute: ActivatedRoute,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private taskService: TaskService,
    private baseControl: BaseControlService,
    private ngZone: NgZone
  ) {
    super();
    baseControl.SignatureView.subscribe(r => {
      ngZone.run(() => {
        this.ifShowSignature = true;
        this.traningId = r.id;
        this.ifCanBack = r.canBack;
        setTimeout(() => {
          this.setOptions();
        }, 1000);
      });
    });
  }

  ngOnInit() {
    this.activedRoute.queryParamMap.subscribe((parmas) => {
      this.traningId = parseInt(parmas.get('taskId'), 10);
      this.isFromLearning = parmas.get('isFromLearning');
      if (this.traningId) {
        this.fetchTsakDetail(this.traningId);
      }
    });
  }

  ngAfterViewInit() {
    this.setOptions();
  }

  // show(id, ifSigntauure) {
  //   this.traningId = id;
  //   this.isFromLearning = ifSigntauure;
  //   if (this.traningId) {
  //     this.fetchTsakDetail(this.traningId);
  //   }
  // }

  setOptions() {
    if (this.signaturePad) {
      this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
      this.signaturePad.set('canvasWidth', window.screen.width);
      this.signaturePad.set('canvasHeight', window.screen.height);
      this.signaturePad.set('backgroundColor', 'rgb(255, 255, 255)');
      this.signaturePad.clear();
    }
  }

  ionWillEnter() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.isDrawing = false;
  }

  drawStart() {
    this.isDrawing = true;
    this.noCanClickSubmit = false;
  }

  // 提交
  submitSignature() {
    console.log(this.traningId);
    if (!this.signaturePad.isEmpty()) {
      this.noCanClickSubmit = false;
      this.signature = this.signaturePad.toDataURL().substring(22);
      this.signatureService.getSignature(this.traningId, this.signature).subscribe(() => {
        if (this.asComponent) {
          this.baseControl.SignatureFinish.next(this.traningId);
          this.clearSignature();
          this.ifShowSignature = false;
        }
        this.toastCtrl.create({
          message: '签名成功',
          duration: 1000,
          position: 'middle',
          cssClass: 'toast-style'
        }).then(toast => {
          toast.onWillDismiss().then(() => {

          });
          toast.present();
        });
      }, (error: SerivceError) => {
        if (error.status === 0) {
         this.showAlert(this.alertCtrl, '提示', `网络连接不可用`);
        }
        if (error.status === 400) {
          this.showAlert(this.alertCtrl, '错误', error.errorMessage);
        }
        if (error.status > 403) {
          this.showAlert(this.alertCtrl, '错误', error.errorMessage);
        }
      });
    } else {
      this.noCanClickSubmit = true;
    }
  }

  // 清空
  clearSignature() {
    this.signaturePad.clear();
    this.signature = '';
    this.noCanClickSubmit = true;
  }

  private fetchTsakDetail(id: number) {
    this.taskService.getTaskDetail(id).subscribe(result => {
      this.taskArticleList = result.articles;
    });

  }
}
