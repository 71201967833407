import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VersionControlJson, VersionControl } from '../models/version-control.model';


@Injectable({
  providedIn: 'root'
})

export class VersionControlService {

  constructor(
    private httpClient: HttpClient) { }

  // 查询app最新版本号
  getAppLastVersion(os: string, env: string ): Observable<VersionControl> {
    return this.httpClient.get<VersionControlJson>(environment.baseUrl + `/noauth/versions/latest`, {
      params: { os, env }
    }).pipe(
      map( json => new VersionControl(json) )
    );
  }



}
