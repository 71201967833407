import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

interface ObservableAndObserver {
  subject: Subject<any>;
  observable: Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly _subscribers = new Map<string, ObservableAndObserver>();

  constructor() {}

  /**
   * 获取通知接收观察者
   */
  getSubscriber(key: string): Observable<any> {
    return this.getObservableAndObserver(key).observable;
  }

  /**
   * 发送通知
   */
  pushNotification(key: string, value?: any) {
    const subject = this.getObservableAndObserver(key).subject;
    subject.next(value);
  }

  /**
   * 销毁通知
   */
  destory(key: string) {
    const value = this._subscribers.get(key);
    if (!value) {
      return;
    }
    const subject = value.subject;
    subject.complete();
    this._subscribers.delete(key);
  }

  private getObservableAndObserver(key: string): ObservableAndObserver {
    let value = this._subscribers.get(key);
    if (!value) {
      const subject = new Subject<any>();
      const observable = subject.pipe(share());
      value = {
        observable,
        subject
      };
      this._subscribers.set(key, value);
    }
    return value;
  }
}
