
// 任务计划列表
export interface TrainJson {
  id: number;
  name: string;
  date: string;
  video_count: number;
  hours: number; // 课时
  pay_status: number;
  progress: number;
  open_test: number;
  question_progress: number;
  supp_training_status?: number;
  training_end_date?: string;
}

export class Train {

  id: number;
  name: string;
  date: string;
  videoCount: number;
  hours: number; // 课时
  payStatus: number;
  progress: number;
  openTest: number;
  questionProgress: number;
  supp_training_status?: number;
  training_end_date?: string;


  constructor(json?: TrainJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: TrainJson): Train {
    this.id = json.id;
    this.name = json.name;
    this.date = json.date;
    this.videoCount = json.video_count;
    this.hours = json.hours;
    this.payStatus = json.pay_status;
    this.progress = json.progress;
    this.openTest = json.open_test;
    this.questionProgress = json.question_progress;
    this.supp_training_status = json.supp_training_status;
    this.training_end_date = json.training_end_date;
    return this;
  }

}

// 任务计划 详情
export interface TrainDetailJson {
  id: number;
  name: string;
  hours: number; // 课时
  date: string;
  progress: number;
  signature: string;
  has_paid: boolean;
  open_test: number;
  is_training: boolean; // 现场学习是否进行中
  articles: {
    id: number;
    title: string;
    hours: number;
    progress: number;
    watched_duration: number;
    duration: number;
    last_watched_time: number;
  }[];
}

export class TrainDetail {
  id: number;
  name: string;
  hours: number; // 课时
  date: string;
  progress: number;
  signature: string;
  hasPaid: boolean | string;
  openTest: number;
  isTraining: boolean; // 现场学习是否进行中
  articles: {
    id: number;
    title: string;
    hours: number;
    progress: number;
    watchedDuration: number;
    duration: number;
    lastWatchedTime: number;
  }[];

  constructor(json?: TrainDetailJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: TrainDetailJson): TrainDetail {
    this.id = json.id;
    this.name = json.name;
    this.hours = json.hours;
    this.date = json.date;
    this.progress = json.progress;
    this.signature = json.signature;
    this.hasPaid = json.has_paid;
    this.openTest = json.open_test;
    this.isTraining = json.is_training;
    this.articles = json.articles.map((item) => {
      return {
        id: item.id,
        title: item.title,
        hours: item.hours,
        progress: item.progress,
        watchedDuration: item.watched_duration,
        duration: item.duration,
        lastWatchedTime: item.last_watched_time
      };
    });

    return this;
  }

}

// 获取用户的所有学习计划列表
export interface UserTrainJson {
  id: number;
  name: string;
  date: string;
  hours: number; // 课时
  progress: number;
  articles: {
    title: string,
    progress: number;
  }[];

}

export class UserTrain {

  id: number;
  name: string;
  date: string;
  hours: number; // 课时
  progress: number;
  articles: {
    title: string,
    progress: number;
  }[];


  constructor(json?: UserTrainJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: UserTrainJson): UserTrain {
    this.id = json.id;
    this.name = json.name;
    this.date = json.date;
    this.hours = json.hours;
    this.progress = json.progress;
    this.articles = json.articles.map((item) => {
      return {
        title: item.title,
        progress: item.progress
      };
    });

    return this;
  }

}


// 获取用户看书统计
export interface UserProgressJson {
  ebook_count: number;
  article_count: number;
  articles: {
    id: number;
    title: string,
    progress: number;
    is_ebook: boolean;
  }[];
}

export class UserProgress {

  ebookCount: number;
  articleCount: number;
  articles: {
    id: number,
    title: string,
    progress: number;
    isEbook: boolean;
  }[];


  constructor(json?: UserProgressJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: UserProgressJson): UserProgress {
    this.ebookCount = json.ebook_count;
    this.articleCount = json.article_count;
    this.articles = json.articles.map((item) => {
      return {
        id: item.id,
        title: item.title,
        progress: item.progress,
        isEbook: item.is_ebook
      };
    });

    return this;
  }

}


// 获取学习计划里文章的完成情况
export interface TrainingsArticleDetailJson {
  id: number;
  title: number;
  content: string;
  picture_url: string;
  video_url: string;
  duration: number;
  date: string;
  watched_duration: number;
  progress: number;
  first_not_watched_durations: {
    start: number;
    end: number;
  };
}

export class TrainingsArticleDetail {

  id: number;
  title: number;
  content: string;
  pictureUrl: string;
  videoUrl: string;
  duration: number;
  date: string;
  watchedDuration: number;
  progress: number;
  firstNotWatchedDurations: {
    start: number;
    end: number;
  };
  constructor(json?: TrainingsArticleDetailJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: TrainingsArticleDetailJson): TrainingsArticleDetail {
    this.id = json.id;
    this.title = json.title;
    this.content = json.content;
    this.pictureUrl = json.picture_url;
    this.videoUrl = json.video_url;
    this.duration = json.duration;
    this.date = json.date;
    this.watchedDuration = json.watched_duration;
    this.progress = json.progress;
    this.firstNotWatchedDurations = json.first_not_watched_durations;
    return this;
  }

}



// 获取学习计划里文章的完成情况
export interface ArticleQuestionJson {
  id: number;
  article_id: number;
  question_name: string;
  status: number;
  options: {
    id: number;
    article_question_id: number;
    option_content: string;
    is_right: number;
    isOkAnswer: boolean;
    isWrongAnswer: boolean;
  }[];
  first_answers: number; // 首轮答题结果，0：错误，1：正确
}

export class ArticleQuestion {

  id: number;
  articleId: number;
  questionName: string;
  status: number;
  myAnswer?: number; // 选择的答案
  options: {
    id: number;
    articleQuestionId: number;
    optionContent: string;
    isRight: number;
    isOkAnswer: boolean;
    isWrongAnswer: boolean;
  }[];
  firstAnswers: number;


  constructor(json?: ArticleQuestionJson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: ArticleQuestionJson): ArticleQuestion {
    this.id = json.id;
    this.articleId = json.article_id;
    this.questionName = json.question_name;
    this.status = json.status;
    this.options = json.options.map((item) => {
      return {
        id: item.id,
        articleQuestionId: item.article_question_id,
        optionContent: item.option_content,
        isRight: item.is_right,
        isOkAnswer: item.isOkAnswer,
        isWrongAnswer: item.isWrongAnswer
      };
    });
    this.firstAnswers = json.first_answers;
    return this;
  }

}


// 回顾
export interface ArticleQuestionAndFirstAnswersJson {
  id: number;
  article_id: number;
  question_name: string;
  options: {
    id: number;
    article_question_id: number;
    option_content: string;
    is_right: number;
    isOkAnswer: boolean;
    isWrongAnswer: boolean;
  }[];
}



export class TrainQuestion {
  planId: number;
  question: ArticleQuestion[];
  progress?: number;
  currentQuestionId?: number;
}

export class WrongQuestion {
  planId: number;
  question: ArticleQuestion[];
}













