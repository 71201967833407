import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Service返回的错误对象
 */
export interface SerivceError {
    status: number;
    errorCode: string;
    errorMessage: string;
    originalError: any;
    errmsg?: string;
}

/**
 * 创建完整的ServiceError
 * @param status HTTP返回码
 * @param errorCode 服务器返回的errorCode
 * @param errorMessage 服务器返回的errorMessage
 * @param originalError 原始Error对象
 */
export const createServiceError = (status: number, errorCode: string, errorMessage: string, originalError: Error) => {
  return {
    status,
    errorCode,
    errorMessage,
    originalError
  };
};

/**
 * 处理应用内服务的错误
 * @param error 原始错误对象
 */
export const createServiceErrorObservable = (error: Error) => {
  if (error instanceof HttpErrorResponse) {
    if (error.status >= 500) {
      return throwError(createServiceError(error.status, 'service_error', '网络异常', error));
    } else if (error.error) {
      const code = typeof error.error.errcode !== 'undefined' ? error.error.errcode : null;
      const msg = typeof error.error.errmsg !== 'undefined' ? error.error.errmsg : null;
      return throwError(createServiceError(error.status, code, msg, error));
    } else {
      return throwError(createServiceError(error.status, null, null, error));
    }
  } else {
    return throwError(createServiceError(-1, '', error.message, error));
  }
};
