import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.heading = (text: string, level: number, raw: string, slugger: marked.Slugger) => {
    return (
      `<h${level} class="header-h${level}">
        ${text}
      </h${level}>`
    );
  };

  return {
    // tslint:disable-next-line:object-literal-shorthand
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}
